<template>
    <div>
        <page-header
            title="Deposit Crypto"
            show-back
            @onClose="returnToRoot"
        />
        <div
            v-show="!accountIsClosed && isCryptoProduct"
            class="text-decoration-none text-primary cursor-pointer"
        >
            <div class="card card-shadow-tall d-flex mx-2 mx-md-3 align-items-center p-2">
                <p class="mb-0 fw-bold">
                    Bitcoin Address
                </p>
                <p class="mt-0 text-muted">
                    {{ btcDepositAddress }}
                </p>
                <form-button
                    class="mt-1 w-100"
                    :label="this.isLinkCopied ? '✓ Copied' : 'Copy'"
                    type="submit"
                    button-classes="btn btn-secondary"
                    @click="copyToClipboard"
                    data-testid="copy-bitcoin-address-button"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { RoutePaths } from '@/routes/router'
    import FormButton from '@/components/base/FormButton'
    import PageHeader from '@/components/PageHeader'
    import { navigation } from '@/mixins/navigation'

    export default {
        name: 'Card',
        components: {
            PageHeader,
            FormButton,
        },
        data: function () {
            return {
                isLinkCopied: false,
            }
        },
        mixins: [navigation],
        computed: {
            ...mapGetters(['accountIsClosed', 'isCryptoProduct', 'btcDepositAddress']),
        },
        mounted: async function () {
            this.$logEvent('view_deposit_crypto')
        },
        methods: {
            ...mapActions(['updateAccountOverview']),
            // for consistency since app's will attempt refresh when view is foregrounded.
            refreshData: async function () {
                await this.updateAccountOverview()
            },
            logout: function () {
                this.$logEvent('click_logout')
                this.$router.push(RoutePaths.LOGOUT)
            },
            copyToClipboard: function () {
                const el = document.createElement('textarea')
                el.value = this.btcDepositAddress
                document.body.appendChild(el)
                el.select()
                document.execCommand('copy')
                document.body.removeChild(el)
                this.isLinkCopied = true
                setTimeout(() => (this.isLinkCopied = false), 2000)
                this.$logEvent('event_crypto_deposit_address_copied')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/appBase';
</style>
